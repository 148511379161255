.button-wrapper {
  background-color: white;
  height: 38px;
  width: 134px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  gap: 8px;
  cursor: pointer;
}
.button-title {
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 4.5%;
  text-align: left;
}
.sub-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.045em;
  text-align: left;
}
.social-icon {
  width: 21px;
  height: 25px;
}

@media (max-width: 400px) {
  .button-wrapper {
    height: 35px;
    width: 130px;
  }
}
