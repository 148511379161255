.backgroundImage {
  background-image: url("../assets/pngs/bg-img.png");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.content {
  padding: 2rem 20rem;
  position: relative;
  z-index: 1;
}
.icon-container {
  display: flex;
  align-items: center;
  gap: 22px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.heading {
  font-size: 80px;
  font-weight: 600;
  line-height: 97px;
  font-style: normal;
  text-transform: uppercase;
  color: #eaa85d;
  text-shadow: 0px 0px 24px rgba(255, 119, 0, 0.6);
}
.scale-wrapper {
  margin-top: 233px;
}
.chinatown-items {
  margin-top: 23px;
}
.paragraph {
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  width: 600px;
  margin-top: 98px;
  font-style: normal;
  color: #ffffff;
}
.buttons-container {
  margin-top: 63px;
  gap: 16px;
  display: flex;
  align-items: center;
}

@media (max-width: 1500px) {
  .scale-wrapper {
    margin-top: 130px;
  }
  .paragraph {
    font-size: 18px;
    line-height: 22px;
    width: 460px;
    margin-top: 70px;
  }
  .content {
    padding: 1rem 3rem;
  }
  .chinatown-icon {
    width: 35px;
    height: 35px;
  }
  .title {
    font-size: 18px;
    line-height: 22px;
  }
  .icon-container {
    gap: 15px;
  }
  .heading {
    font-size: 60px;
    line-height: 72px;
  }
  .chinatown-items {
    margin-top: 10px;
    width: 106px;
    height: 36px;
  }

  .buttons-container {
    margin-top: 63px;
    gap: 16px;
  }
}

@media (max-width: 800px) {
  .content {
    padding: 1rem 2rem;
  }
  .scale-wrapper {
    margin-top: 0px;
    min-height: 90vh;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .heading {
    margin-top: 0rem;
    font-size: 60px;
    line-height: 64px;
  }
  .backgroundImage {
    background-image: url("../assets/pngs/bg-ipad.png");
  }
}

@media (max-width: 560px) {
  .content {
    padding: 1rem 1rem;
  }
  .scale-wrapper {
    margin-top: 60px;
    min-height: 75vh;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .icon-container {
    justify-content: center;
    gap: 10px;
  }
  .heading {
    font-size: 40px;
    line-height: 44px;
    text-align: center;
  }
  .chinatown-items {
    display: flex;
    margin: auto;
    margin-bottom: 40px;
    margin-top: 68px;
  }
  .paragraph {
    font-size: 16px;
    line-height: 20px;
    width: 370px;
    text-align: center;
    margin: auto;
  }
  .buttons-container {
    margin-top: 120px;
    justify-content: center;
  }
  .backgroundImage {
    background-image: url("../assets/pngs/bg-ipad.png");
  }
}

@media (max-width: 415px) {
  .heading {
    font-size: 30px;
    line-height: 36px;
  }
  .scale-wrapper {
    min-height: 65vh;
    margin-top: 80px;
  }
  .chinatown-items {
    width: 70px;
    margin-top: 52px;
  }
  .paragraph {
    font-size: 14px;
    line-height: 22px;
    width: 290px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
  }
  .buttons-container {
    margin-top: 80px;
    justify-content: center;
  }
  .backgroundImage {
    background-image: url("../assets/pngs/bg-phone.png");
  }
}
